import { Dayjs } from 'dayjs';

export enum EJobTypes {
  WORK = 'ΕΡΓΑΣΙΑ',
  REMOTE = 'ΤΗΛΕΡΓΑΣΙΑ',
  DAY_OFF = 'ΑΝΑΠΑΥΣΗ/ΡΕΠΟ',
  NON_WORK = 'ΜΗ ΕΡΓΑΣΙΑ',
  NO_ACTION = 'ΚΑΜΙΑ ΕΝΕΡΓΕΙΑ',
}

export type DaysType = '1' | '2' | '3' | '4' | '5' | '6' | '0';

export type TimeRangeType = {
  f_from: Dayjs | string;
  f_to: Dayjs | string;
}

export type JobInfoType = {
  f_day: string;
  type: string,
  departmentID: string,
  WTO: TimeRangeType[];
}