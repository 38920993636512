import { ICalendar_Sync } from "../models/calendar";
import { apiClient } from './apiClient';

export const getICalendar_Sync = async (): Promise<ICalendar_Sync[]> => {
  try {
    const response = await apiClient.get<ICalendar_Sync[]>('/calendar_sync_ergani');
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};