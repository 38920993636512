import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../../store/store';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Chip } from '@mui/material';

import StyledBadge from './styledComponents/StyledBadge';

import { useGenerateAccount } from '../../hooks/useAccountData';

const CustomAvatar = () => {
  const navigate = useNavigate();
  const account = useGenerateAccount();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openTooltip = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

    persistor.purge();
    navigate('/login');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Chip label={account?.companyName?.substring(0, 25)} size="medium" color="primary" />
        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
          <Button
            onClick={handleClick}
            sx={{ ml: 1, textTransform: 'none', borderRadius: '20%' }}
            aria-controls={openTooltip ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openTooltip ? 'true' : undefined}
          >
            <Typography align="center" component="h3" color="primary" variant="h6" sx={{ px: 1 }}>
              {account?.email}
            </Typography>
            <Avatar sx={{ width: 32, height: 32 }}>U</Avatar>
          </Button>
        </StyledBadge>
        {/*<Tooltip title="Λεπτομέρειες λογαριασμού">*/}
        {/*  <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">*/}
        {/*   */}
        {/*  </StyledBadge>*/}
        {/*</Tooltip>*/}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openTooltip}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar /> Προφίλ
        </MenuItem>
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <Avatar /> My account*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <PersonAdd fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Add another account*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Settings fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Settings*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Αποσύνδεση
        </MenuItem>
      </Menu>
    </>
  );
};

export default CustomAvatar;
