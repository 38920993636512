import { Grid } from '@mui/material';

import CustomTabPanel from '../../../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../../../components/FormFields/ViewOnlyTextInput';

import { useGenerateAccount } from '../../../../../hooks/useAccountData';

type CredentialsTabProps = {
  tab: number;
};

const CredentialsTab = ({ tab }: CredentialsTabProps) => {
  const account = useGenerateAccount();
  
  return (
    <CustomTabPanel value={tab} index={3}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <ViewOnlyTextInput label="Εργάνη Username" name="erganiUser" value={account?.erganiUser} />
        </Grid>
        <Grid item xs={5}>
          <ViewOnlyTextInput label="Εργάνη Password" name="erganiPass" value={account?.erganiPass} />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default CredentialsTab;
