import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { PaletteMode } from '@mui/material';
import CustomAvatar from '../CustomAvatar/CustomAvatar';
import StyledAppBar from './styledComponents/StyledAppBar';

type PrimarySearchAppBarProps = {
  mode: PaletteMode;
  open: boolean;
  toggleColorMode: () => void;
  toggleDrawer: () => void;
};

const PrimarySearchAppBar = ({ mode, open, toggleColorMode, toggleDrawer }: PrimarySearchAppBarProps) => {

  return (
    <StyledAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }}></Typography>
        <CustomAvatar />
      </Toolbar>
    </StyledAppBar>
  );
};

export default PrimarySearchAppBar;
