import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';

import Layout from '../../../hocs/Layout';
import Title from '../../../components/Title/Title';
import ViewOnlyTextInput from '../../../components/FormFields/ViewOnlyTextInput';
import { Translations } from '../../../i18n/translationKeys';
import { TabData } from '../../../typings';
import TabWrapper from '../../../components/TabWrapper/TabWrapper';

import { useHandleTabs } from '../../../hooks/useHandleTabs';
import { useDepartmentsData } from '../../../hooks/useDepartmentsData';

import './BranchesDetails.scss';

const BranchDetails = () => {
  const [departments, departmentsLoaded] = useDepartmentsData();

  const { id: currDepartmentId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab, handleChange } = useHandleTabs();

  if (!departmentsLoaded) return null;

  const departmentInfo = departments?.find(dep => dep.departmentID.toString() === currDepartmentId);

  const handleGoBack = () => {
    navigate(-1);
  };

  const tabsData: TabData[] = [{ label: t(Translations.BASIC_DATA) }];

  return (
    <Layout>
      <Title>Παράρτημα: {currDepartmentId}</Title>
      <ArrowBack onClick={handleGoBack} className="arrowBackButton" />
      <Box sx={{ width: '100%', mt: 2 }}>
        <Box>
          <TabWrapper tabValue={tab} handleChange={handleChange} tabsData={tabsData} />
        </Box>
        <Box component="article" sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <ViewOnlyTextInput label="Περιγραφή" name="description" value={departmentInfo?.description} />
            </Grid>
            <Grid item xs={4}>
              <ViewOnlyTextInput label="Κωδικός" name="code" value={departmentInfo?.departmentID} />
            </Grid>
            <Grid item xs={4}>
              <ViewOnlyTextInput label="Διεύθυνση" name="address" value={departmentInfo?.address} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default BranchDetails;
