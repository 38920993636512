import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { listItemsData } from '../constants';

const SideBarItems = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      {listItemsData.map(({ pathTo, ListIcon, label }) => (
        <ListItemButton
          key={label}
          component={Link}
          to={pathTo}
          sx={{
            boxShadow: location.pathname.includes(pathTo) ? '0px 4px 8px -4px rgba(58, 53, 65, 0.42)' : null,
            backgroundImage: location.pathname.includes(pathTo) ? 'linear-gradient(98deg, #C6A7FE, #9155FD 94%)' : null,
            color: location.pathname.includes(pathTo) ? '#FFFFFF' : '#000000',
            borderTopRightRadius: 25,
            borderBottomRightRadius: 25,
          }}
        >
          <ListItemIcon sx={{ color: location.pathname.includes(pathTo) ? '#FFFFFF' : '#000000' }}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary={t(label)} />
        </ListItemButton>
      ))}
    </>
  );
};

export default SideBarItems;
