import { FormModel } from '../containers/ApplicationForm/formModel';
import { DropDownOptions } from '../typings';

export const mapToDropDownOptions = (currId: any, currValue: any, currLabel: string): DropDownOptions => ({
  id: currId.toString(),
  value: currValue.toString(),
  label: currLabel,
});

export const mapFormValuesToWTOS = (initValues: FormModel) => {
  const transformedValues = Object.values(initValues).map((dayInfo) => {
    const { f_day, departmentID, WTO, type } = dayInfo;
    if (type !== 'ΚΑΜ') {
      return {
        f_day,
        type,
        departmentID,
        wto: type === 'ΜΕ' || type === 'ΑΝ' ? [] : WTO,
      };
    }
    return null;
  });

  return transformedValues.filter((value) => value !== null);
};
