import { Box, Modal, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/el';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

type DetailsModalProps = {
  open: boolean;
  selectedEvent: any;
  setOpen: (value: boolean) => void;
};

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const { open, selectedEvent, setOpen } = props;

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {selectedEvent.surname}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {`ΑΦΜ: ${selectedEvent.afm}`}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {`Έναρξη: ${moment(selectedEvent.start).format('DD/MM/YYYY HH:mm')}`}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {`Λήξη: ${moment(selectedEvent.end).format('DD/MM/YYYY HH:mm')}`}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
