import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUser, IUserDTO } from '../../models/user';
import { login } from '../../api/login';

interface IAuthState {
  user: IUser | null;
  error: string | null;
  loaded: boolean;
  isSubmitting: boolean;
}

const initialState: IAuthState = {
  user: null,
  error: null,
  isSubmitting: false,
  loaded: false,
};

export const loginAction = createAsyncThunk('auth/login', async (data: IUserDTO) => await login(data));

// export const logoutAction = createAsyncThunk('auth/logut', async () => {
//   await logout();
// });

// export const loadCurrentUser = createAsyncThunk(
//   'auth/loadCurrentUser',
//   async () => {
//     const user = await loadUser();
//     return user;
//   }
// );

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.isSubmitting = false;
      });
    //     .addCase(logoutAction.fulfilled, (state) => {
    //       return { ...initialState, loaded: state.loaded };
    //     })
    //     .addCase(loadCurrentUser.fulfilled, (state, action) => {
    //       return { ...state, loaded: true, user: action.payload, error: null };
    //     });
  },
});

export const { clearUser } = authSlice.actions;

// export const useAuthData = () => useReduxData(authUser, authUserLoaded, loadCurrentUser);

export default authSlice.reducer;
