import { apiClient } from './apiClient';
import { IDepartment } from '../models/IDepartment';

export const getDepartments = async (): Promise<IDepartment[]> => {
  try {
    const response = await apiClient.post<IDepartment[]>('/get_departments');
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};