import { Account } from '../models/account';
import { apiClient } from './apiClient';

export const getAccount = async (): Promise<Account[]> => {
  try {
    const response = await apiClient.post<Account[]>('/get_account');
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};