import { Divider, Grid } from '@mui/material';

import { useGenerateAccount } from '../../../../hooks/useAccountData';

import CustomTabPanel from '../../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../../components/FormFields/ViewOnlyTextInput';

import dayjs from 'dayjs';

type BasicInfoTabProps = {
  tab: number;
};

const BasicInfoTab = (props: BasicInfoTabProps) => {
  const { tab } = props;
  const account = useGenerateAccount();

  return (
    <CustomTabPanel value={tab} index={0}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ViewOnlyTextInput label="ΑΦΜ" name="taxId" value={account?.taxId} />
        </Grid>
        <Grid item xs={9}>
          <ViewOnlyTextInput label="Επωνυμία" name="companyName" value={account?.companyName} />
        </Grid>
      </Grid>
      <Divider color="black" sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Email" name="email" value={account?.email} />
        </Grid>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Συνεργάτης" name="dealer" value={account?.dealer} />
        </Grid>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Συνδρομή μέχρι" name="subscriptionUntil" value={account?.subscriptionUntil} />
        </Grid>
      </Grid>
      <Divider color="black" sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ViewOnlyTextInput
            label="Ημ/νία Εισαγωγής"
            name="entryDate"
            value={dayjs(account?.entryDate).format('DD/MM/YYYY')}
          />
        </Grid>
        <Grid item xs={2}>
          <ViewOnlyTextInput
            label="Ημ/νία Τροποποίησης"
            name="modifyDate"
            value={dayjs(account?.modifyDate).format('DD/MM/YYYY')}
          />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default BasicInfoTab;
