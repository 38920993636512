import { ElementType } from 'react';
import { Translations } from '../../i18n/translationKeys';

import SpaceDashboard from '@mui/icons-material/SpaceDashboard';
import Groups from '@mui/icons-material/Groups';
import CreditCard from '@mui/icons-material/CreditCard';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Info from '@mui/icons-material/Info';
import AddBusiness from '@mui/icons-material/AddBusiness';
import Article from '@mui/icons-material/ArticleOutlined';

interface IListItem {
  pathTo: string;
  ListIcon: ElementType;
  label: string;
}

export const listItemsData: IListItem[] = [
  // {
  //   pathTo: '/home',
  //   ListIcon: SpaceDashboard,
  //   label: Translations.LANDING_PAGE,
  // },
  {
    pathTo: '/businessData',
    ListIcon: Info,
    label: Translations.BUSINESS_DATA,
  },
  {
    pathTo: '/branches',
    ListIcon: AddBusiness,
    label: Translations.BRANCHES,
  },
  {
    pathTo: '/employees',
    ListIcon: Groups,
    label: Translations.EMPLOYEES,
  },
  {
    pathTo: '/card-movements',
    ListIcon: CreditCard,
    label: Translations.CARD_MOVEMENTS,
  },
  {
    pathTo: '/application-form',
    ListIcon: Article,
    label: Translations.APPLICATION_FORM,
  },
  {
    pathTo: '/calendar',
    ListIcon: CalendarMonth,
    label: Translations.CALENDAR,
  },
];
