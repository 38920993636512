import { Link } from 'react-router-dom';

import { GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Title from '../../components/Title/Title';
import Layout from '../../hocs/Layout';
import DataGridContainer from '../../components/DataGridContainer/DataGridContainer';

import { useEmployeesData } from '../../hooks/useApplicationData';
import GenericModal from '../../components/Modal/GenericModal';
import { useState } from 'react';
import { getEmployees_sync } from '../../api/employee_sync';
import { loadEmployees } from '../../store/employee/employeeSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';


interface ErrorResponse {
    message: string;
  }

const columns: GridColDef[] = [
  {
    field: 'surname',
    headerName: 'Επώνυμο',
    flex: 1,
    renderCell: (param) => <Link to={`${param.row.id}`}>{param.row.surname}</Link>,
  },
  { field: 'name', headerName: 'Ονομα', flex: 1 },
  { field: 'afm', headerName: 'ΑΦΜ', flex: 1 },
  { field: 'amka', headerName: 'AMKA', flex: 1 },
  { field: 'fatherName', headerName: 'Ονομα Πατέρα', flex: 1 },
  { field: 'isActive', headerName: 'Ενεργός', flex: 0.5, type: 'boolean' },
  { field: 'isDigitalCard', headerName: 'Ψηφιακή Κάρτα', flex: 1, type: 'boolean' },
  {
    field: 'dateEntry',
    headerName: 'Ημερομηνία Εισαγωγής',
    flex: 1,
    type: 'date',
    renderCell: (param) => <>{param.row.dateEntry?.toLocaleDateString('en-GB')}</>,
  },
];

const Employees = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [employeesData, employeesLoaded] = useEmployeesData();
  const [modalVisible, setModalVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [msgData, setMsgData] = useState([{}]);
  const [msgDataError, setMsgDataError] = useState<ErrorResponse | null>(null);


  if (!employeesLoaded) return null;

  const employeesRows = employeesData
    ? employeesData!.map((empData) => ({
      id: empData.employeeID,
      surname: empData.surname,
      name: empData.name,
      afm: empData.afm,
      amka: '',
      fatherName: empData.fathername,
      isActive: empData.status === 1,
      isDigitalCard: true,
      // dateEntry: new Date('2015-03-25'), // TODO: uncomment when data are return from BE
    }))
    : [];

  const handleFetchEmployees = async () => {
    try {
      setModalVisible(true)
      setLoaderVisible(true)
      const response = await getEmployees_sync();
      setLoaderVisible(false)
      setMsgData(response);
      dispatch(loadEmployees());
      // loadEmployees();
    } catch (error) {
      console.error('Error fetching employees:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      setMsgDataError({ message: errorMessage });
    } finally {
      setLoaderVisible(false); // Hide loader
    }
  };
  const clearModal = () => {
    setModalVisible(false);
    setLoaderVisible(false);
    setMsgData([]); 
    setMsgDataError(null)
  };

  return (
    <Layout>
      <Box>
        <Button
          onClick={() => handleFetchEmployees()}
          sx={{ marginBottom: 1 }}
          variant="contained">Ενημέρωση απο ΕΡΓΑΝΗ</Button>
        <Title>Εργαζόμενοι</Title>
        <Box sx={{ height: 600, width: '100%', overflowY: 'auto' }}>
          <DataGridContainer
            rows={employeesRows}
            columns={columns}
          />
        </Box>
      </Box>
      {modalVisible &&
        <GenericModal
          errmsg={msgData}
          failedmsg={msgDataError?.message}
          modaltype={'employee'}
          loaderVisible={loaderVisible}
          clearAction={clearModal}
        />}
    </Layout>
  );
};

export default Employees;