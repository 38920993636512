import { SyntheticEvent } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TabData } from '../../typings';

type TabWrapperProps = {
  tabValue: number;
  handleChange: (e: SyntheticEvent, newValue: number) => void;
  tabsData: TabData[];
};

const TabWrapper = ({ tabValue, handleChange, tabsData }: TabWrapperProps) => {
  const a11yProps = (index: number) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  });

  return (
    <Tabs value={tabValue} onChange={handleChange} aria-label="basic-tabs">
      {tabsData.map(({ label }, index) => (
        <Tab key={`${label}-${index}`} label={label} {...a11yProps(index)} />
      ))}
    </Tabs>
  );
};

export default TabWrapper;
