import dayjs from 'dayjs';
import { useReduxData } from './useReduxData';
import { selectAccount, selectAccountLoaded } from '../store/account/selectors';
import { loadAccount } from '../store/account/accountSlice';
import { Account } from '../models/account';

export const useAccountData = () => useReduxData(selectAccount, selectAccountLoaded, loadAccount);

export const useGenerateAccount = (): any => {
  const [account, accountLoaded] = useAccountData();

  if (!accountLoaded || !account || account.length === 0) return [];

  const transformedAccount = account.map((account: Account) => ({
    taxId: account.company_afm,
    companyName: account.company_name,
    email: account.email,
    doy: account.company_doy,
    profession: account.company_epaggelma,
    mobTel: account.mobtel,
    telNum: account.telnum,
    dealer: account.Dealer,
    subscriptionUntil: account.SubscriptionUntil,
    entryDate: account.created ? dayjs(account.created) : dayjs(null),
    modifyDate: account.updated ? dayjs(account.updated) : dayjs(null),
    businessAddress: account.company_address,
    argemi: account.company_argemi,
    erganiUser: account.ergani_user,
    erganiPass: account.ergani_pass,
    checkinsToday: account.Checkins_today,
    checkoutsToday: account.Checkouts_today,
    employees: account.Employees,
    departments: account.Departments,
  }));

  return transformedAccount[0];
};
