import { createSlice } from '@reduxjs/toolkit';

interface IBasicInfoState {
  isFormDisabled: boolean;
}

const initialState: IBasicInfoState = {
  isFormDisabled: true,
};

export const basicInfoSlice = createSlice({
  name: 'basicInfo',
  initialState,
  reducers: {
    setIsFormDisabled: (state, action) => {
      state.isFormDisabled = action.payload;
    },
  },
});

export const { setIsFormDisabled } = basicInfoSlice.actions;

export default basicInfoSlice.reducer;
