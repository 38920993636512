import { DropDownOptions } from '../../typings';
import { randomId } from '@mui/x-data-grid-generator';
import { EJobTypes } from './typings';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';

export const jobTypeOptions: DropDownOptions[] = [
  {
    id: randomId(),
    value: 'ΚΑΜ',
    label: EJobTypes.NO_ACTION,
  },
  {
    id: randomId(),
    value: 'ΕΡΓ',
    label: EJobTypes.WORK,
  },
  {
    id: randomId(),
    value: 'ΤΗΛ',
    label: EJobTypes.REMOTE,
  },
  {
    id: randomId(),
    value: 'ΑΝ',
    label: EJobTypes.DAY_OFF,
  },
  {
    id: randomId(),
    value: 'ΜΕ',
    label: EJobTypes.NON_WORK,
  },
];

export const useGenerateBranchesOptions = () => {
  const [departments, departmentsLoaded] = useDepartmentsData();

  if (!departmentsLoaded) return [];

  return departments?.map(dep => ({
    id: randomId(),
    value: dep.departmentID.toString(),
    label: dep.description,
  }));
};

export const programTypeOptions: DropDownOptions[] = [
  {
    id: randomId(),
    value: 'weekly',
    label: 'Εβδομαδιαίο σταθερό',
  },
  {
    id: randomId(),
    value: 'daily',
    label: 'Ημερήσιο',
  },
];

// {
//   Code: 'ΑΔΚΑΝ',
//   Description: 'Κανονική άδεια',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΙΜ',
//   Description: 'Αιμοδοτική άδεια',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΕΞ',
//   Description: 'Άδεια εξετάσεων',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΑ',
//   Description: 'Άδεια άνευ αποδοχών',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΜΗ',
//   Description: 'Άδεια μητρότητας',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΠΠΜ',
//   Description: 'Ειδική παροχή προστασίας της μητρότητας',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΠΑ',
//   Description: 'Άδεια πατρότητας',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΦΠ',
//   Description: 'Άδεια φροντίδας παιδιού',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΓΟΝ',
//   Description: 'Γονική άδεια',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΦΡΟ',
//   Description: 'Άδεια φροντιστή',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΠΑΒ',
//   Description: 'Απουσία από την εργασία για λόγους ανωτέρας βίας',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΙΥΑ',
//   Description: 'Άδεια για υποβολή σε μεθόδους ιατρικώς υποβοηθούμενης αναπαραγωγής',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΠΕ',
//   Description: 'Άδεια εξετάσεων προγεννητικού ελέγχου',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΓΑΜ',
//   Description: 'Άδεια γάμου',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΣΝΠ',
//   Description: 'Άδεια λόγω σοβαρών νοσημάτων των παιδιών',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΝΠ',
//   Description: 'Άδεια λόγω νοσηλείας των παιδιών',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΜΟ',
//   Description: 'Άδεια μονογονεϊκών οικογενειών',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΠΣΕΤ',
//   Description: 'Άδεια παρακολούθησης σχολικής επίδοσης τέκνου',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΠΕΜ',
//   Description: 'Άδεια λόγω ασθένειας παιδιού ή άλλου εξαρτώμενου μέλους',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΠΣΚ',
//   Description: 'Απουσία από την εργασία λόγω επικείμενου σοβαρού κινδύνου βίας ή παρενόχλησης',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΣ',
//   Description: 'Άδεια ασθένειας (ανυπαίτιο κώλυμα παροχής εργασίας)',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΜΕΑ',
//   Description: 'Άδεια απουσίας Α.Μ.Ε.Α.',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΘΣΥΓ',
//   Description: 'Άδεια λόγω θανάτου συγγενούς ',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΝΣΠ',
//   Description: 'Άδεια ανήλικων σπουδαστών',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΜΑΑ',
//   Description: 'Άδεια για μεταγγίσεις αίματος και των παραγώγων του ή αιμοκάθαρση',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΕΚΦ',
//   Description: 'Εκπαιδευτική άδεια για φοιτητές στο Κ.ΑΝ.Ε.Π. - Γ.Σ.Ε.Ε.',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΣΕΑΑ',
//   Description: 'Άδεια λόγω AIDS',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΕΡΕ',
//   Description: 'Ευέλικτες ρυθμίσεις εργασίας',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΦΠ',
//   Description: 'Άδεια φροντίδας παιδιού (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΓΟΝ',
//   Description: 'Γονική άδεια (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΑΠΑΒ',
//   Description: 'Απουσία από την εργασία για λόγους ανωτέρας βίας (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΕΡΕ',
//   Description: 'Ευέλικτες ρυθμίσεις εργασίας (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΠΕ',
//   Description: 'Άδεια εξετάσεων προγεννητικού ελέγχου (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΠΣΕΤ',
//   Description: 'Άδεια παρακολούθησης σχολικής επίδοσης τέκνου (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
// {
//   Code: 'ΑΔΑΛ',
//   Description: 'Άδεια Άλλη',
//   Extra: 'ΑΔΕΙΑ-ΑΔΕΙΑ',
// },
// {
//   Code: 'ΩΑΑΛ',
//   Description: 'Άδεια Άλλη (ΩΡΕΣ)',
//   Extra: 'ΑΔΕΙΑ-ΩΡΟΑΔΕΙΑ',
// },
