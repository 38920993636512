import { useReduxData } from './useReduxData';
import { selectDepartments, selectDepartmentsLoaded } from '../store/department/selectors';
import { loadDepartments } from '../store/department/departmentSlice';
import { IDepartment } from '../models/IDepartment';

export const useDepartmentsData = () => useReduxData(selectDepartments, selectDepartmentsLoaded, loadDepartments);

export const useGenerateDepartments = (): Omit<IDepartment, 'departmentID'>[] => {
  const [departments, departmentsLoaded] = useDepartmentsData();

  if (!departmentsLoaded) return [];

  return departments!.map(({ departmentID, address, description }) => ({
    id: departmentID,
    description,
    address,
    code: departmentID,
  }));
};