import { useEffect } from 'react';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../store/app-hooks';

import { RootState } from '../store/store';

export const useReduxData = <T>(
  dataSelector: (state: RootState) => T,
  dataLoadedSelector: (state: RootState) => boolean,
  dispatchAction: () => AsyncThunkAction<any, any, any>
): [T, boolean] => {
  const dataLoaded = useAppSelector(dataLoadedSelector);
  const data = useAppSelector(dataSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(dispatchAction());
    }
  }, [dataLoaded, dispatch, dispatchAction]);

  return [data, dataLoaded];
};
