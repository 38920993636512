import { IEmployee } from '../models/employee';
import { apiClient } from './apiClient';

export const getEmployees = async (): Promise<IEmployee[]> => {
  try {
    const response = await apiClient.get<IEmployee[]>('/employees');
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};