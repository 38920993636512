import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import notFoundPage from '../../assets/img/not-found.jpg';

import './NotFound.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404 Not Found</h1>
        <p>Oops! Φαίνεται ότι έχετε πέσει πάνω σε μια σελίδα που δεν υπάρχει.</p>
        <img src={notFoundPage} alt="Lost in space" className="not-found-image" />
        <p>Μην ανησυχείτε, θα σας οδηγήσουμε πίσω στην αρχική σελίδα</p>
        <Button onClick={() => navigate('businessData')} variant="contained">
          Αρχικη
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
