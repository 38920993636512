export const el = {
  //Login
  welcomeTitle: 'Καλώς ορίσατε στην Κάρτα ΕΡΓΑΝΗ!',
  connectToYourAccount: 'Συνδεθείτε στο λογαριασμό σας',
  loginButton: 'Σύνδεση',
  username: 'Username',
  password: 'Κωδικός',
  rememberMe: 'Θυμήσου με',
  forgotPassword: 'Ξεχάσατε τον κωδικό σας;',
  registerHere: 'Κάντε εγγραφή εδώ',

  // Global Sidebar
  landingPage: 'Αρχική Σελίδα',
  businessData: 'Βασικά Στοιχεία',
  branches: 'Παραρτήματα',
  employees: 'Εργαζόμενοι',
  cardMovements: 'Κινήσεις Κάρτας',
  calendar: 'Ημερολόγιο',
  applicationForm: 'Αίτηση ωραρίου',

  // App bar
  search: 'Αναζήτηση…',

  //Basic tabs
  basicData: 'Στοιχεία Εταιρείας',
  addresses: 'Στοιχεία Επικοινωνίας',
  taxData: 'Φορολογικά Στοιχεία',
  credentials: 'Διαπιστευτήρια',
  statistics: 'Στατιστικά',
  // Days
  '1': 'Δευτέρα',
  '2': 'Τρίτη',
  '3': 'Τετάρτη',
  '4': 'Πέμπτη',
  '5': 'Παρασκευή',
  '6': 'Σάββατο',
  '0': 'Κυριακή',
};
