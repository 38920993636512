import { useAppSelector } from '../store/app-hooks';
import { selectCardActions } from '../store/cardAction/selectors';
import { useEmployeesData } from './useApplicationData';
import { useDepartmentsData } from './useDepartmentsData';

export const useProduceCardMovementData = () => {
  const [employeesData, employeesLoaded] = useEmployeesData();
  const [departments, departmentsLoaded] = useDepartmentsData();
  const cardActions = useAppSelector(selectCardActions);

  // console.log("employeesData", employeesData);
  // console.log("cardActions", cardActions);

  let data = [];

  if (employeesLoaded && departmentsLoaded && employeesData && cardActions) {
    data = employeesData.flatMap((employee) => {
      const matchingCardActions = cardActions.filter(
        (action) => action.employeeID === employee.employeeID
      );
      return matchingCardActions.map((action) => ({
        id: Math.floor(Math.random() * 1000000) + 1,  // Generate a random ID
        surname: employee.surname,
        name: employee.name,
        afm: employee.afm,
        branch: departments.find(dep => dep.departmentID === employee.departmentID)?.description,
        cardType: action.type === 0 ? 'Προσέλευση' : 'Αποχώρηση',
        actionDate: new Date(action.time),
        state: employee.status === 0 ? 'Μη Απεσταλμένη' : 'Απεσταλμένη',
      }));
    });
  }

  console.log("Final Data:", data);

  return data;
};
