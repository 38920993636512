import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEmployee } from '../../models/employee';
import { getEmployees } from '../../api/employee';
import { PURGE } from 'redux-persist/es/constants';

interface IEmployeeState {
  employeesData: IEmployee[] | null;
  error: string | null;
  loaded: boolean;
}

export const initialState: IEmployeeState = {
  employeesData: null,
  error: null,
  loaded: false,
};

export const loadEmployees = createAsyncThunk(
  'employees/loadEmployees',
  async () => await getEmployees(),
);

export const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadEmployees.pending, (state) => {
        state.error = null;
      })
      .addCase(loadEmployees.fulfilled, (state, action) => {
        state.employeesData = action.payload;
        state.loaded = true;
        state.error = null;
      })
      .addCase(loadEmployees.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(PURGE, (state) => {
        state.employeesData = null;
        state.loaded = false;
      });

  },
});


export default employeeSlice.reducer;
