import { selectCalendarData } from '../store/calendar/selectors';
import { useEmployeesData } from './useApplicationData';
import { useAppSelector } from '../store/app-hooks';
import DetailsContent from '../containers/Calendar/components/DetailsContent';
import { ICalendar, ICalendarFlattened } from '../models/calendar';

const calculateDateData = (calData: ICalendarFlattened) => {
  const { date, wto } = calData;
  const currDate = new Date(date);

  const year = currDate.getFullYear();
  const month = currDate.getMonth();
  const day = currDate.getDate();

  // Splits the fromHour, toHourString string (e.g., "06:30") into two parts, "06" and "30".
  const [fromHourString, fromMinuteString] = wto.f_from.split(':');
  const [toHourString, toMinuteString] = wto.f_to.split(':');

  //  Converts the hour & minutes part to an integer
  const fromHour = parseInt(fromHourString, 10);
  const fromMinutes = parseInt(fromMinuteString, 10);
  const toHour = parseInt(toHourString, 10);
  const toMinutes = parseInt(toMinuteString, 10);

  return { year, month, day, fromHour, fromMinutes, toHour, toMinutes };
};

// iterates events and splits each 'wto' object into a separate entry in case 2 or more wtos exists
const transformCalendarData = (calendarData: ICalendar[]) =>
  calendarData.flatMap(({ date, type, wto }) =>
    wto.map((timeSlot) => ({
      date,
      type,
      wto: timeSlot,
    })),
  );

// custom hook to create the calendar events for specific employee
export const useGenerateCalendarEvents = (empId: string) => {
  const calendarData = useAppSelector(selectCalendarData);
  const [employeesData, employeesLoaded] = useEmployeesData();

  if (!employeesLoaded || !calendarData) return;

  // find current employee data
  const currentEmployee = employeesData?.find(({ employeeID }) => employeeID.toString() === empId);

  const updatedCalendarData = transformCalendarData(calendarData);

  return updatedCalendarData.map(calData => {
    const { year, month, day, fromHour, fromMinutes, toHour, toMinutes } = calculateDateData(calData);

    return {
      id: Math.random(),
      title: <DetailsContent label="ΠΑΡΑΡΤΗΜΑ 1" from={calData?.wto.f_from} to={calData?.wto.f_to} />,
      start: new Date(year, month, day, fromHour, fromMinutes),
      end: new Date(year, month, day, toHour, toMinutes),
      surname: `${currentEmployee?.name} ${currentEmployee?.surname}`,
      afm: currentEmployee?.afm,
      backgroundColor: calData?.type === 'ΕΡΓ' ? 'green' : 'red',
    };
  });
};
