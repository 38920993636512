import axios from 'axios';
import { store } from '../store/store';
import { selectAuthToken } from '../store/auth/selectors';

//const apiClient = axios.create({ baseURL: 'http://karta-ergani.gr:5005' });
const apiClient = axios.create({ baseURL: 'https://backend.karta-ergani.gr' });

apiClient.interceptors.request.use(async (request) => {
  const token = selectAuthToken(store.getState());

  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }

  return request;
});

apiClient.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error.response.data.msg || error.response.data.message),
);

export { apiClient };
