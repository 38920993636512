import { createBrowserRouter } from 'react-router-dom';

import Login from '../containers/Login/Login';
import Employees from '../containers/Employees/Employees';
import BusinessData from '../containers/BusinessData/BusinessData';
import CardMovements from '../containers/CardMovements/CardMovements';
import Calendar from '../containers/Calendar/Calendar';
// import Home from '../containers/Home/Home';
import NotFound from '../containers/NotFound/NotFound';
import Branches from '../containers/Branches/Branches';
import ProtectedRoute from './ProtectedRoute';
import EmployeeDetails from '../containers/Employees/EmployeeDetails';
import BranchDetails from '../containers/Branches/styledComponents/BranchesDetails';
import ApplicationForm from '../containers/ApplicationForm/ApplicationForm';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute element={<BusinessData />} />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  // {
  //   path: 'home',
  //   element: <ProtectedRoute element={<Home />} />,
  // },
  {
    path: 'businessData',
    element: <ProtectedRoute element={<BusinessData />} />,
  },
  {
    path: 'branches',
    element: <ProtectedRoute element={<Branches />} />,
  },
  {
    path: 'branches/:id',
    element: <ProtectedRoute element={<BranchDetails />} />,
  },
  {
    path: 'employees',
    element: <ProtectedRoute element={<Employees />} />,
  },
  {
    path: 'employees/:id',
    element: <EmployeeDetails />,
  },
  {
    path: 'card-movements',
    element: <ProtectedRoute element={<CardMovements />} />,
  },
  {
    path: 'calendar',
    element: <ProtectedRoute element={<Calendar />} />,
  },
  {
    path: 'application-form',
    element: <ProtectedRoute element={<ApplicationForm />} />,
  },
  { path: '*', element: <NotFound /> },
]);

export default router;
