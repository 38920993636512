import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

import DataGridContainer from '../../../components/DataGridContainer/DataGridContainer';
import GenericModalError from '../../../components/Modal/GenericModalError';
import { useGenerateDepartments } from '../../../hooks/useDepartmentsData';
import { useAppSelector } from '../../../store/app-hooks';
import { selectDepartmentsError } from '../../../store/department/selectors';

const columns: GridColDef[] = [
  {
    field: 'description',
    headerName: 'Περιγραφή',
    flex: 2,
    align: 'left',
    headerAlign: 'left',
    renderCell: (param) => <Link to={`/branches/${param.id}`}>{param.row.description}</Link>,
  },
  {
    field: 'code',
    headerName: 'Κωδικός',
    flex: 0.5,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'address',
    headerName: 'Διεύθυνση',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
];

const BranchesDataGrin = () => {
  const departments = useGenerateDepartments();
  const departmentsError = useAppSelector(selectDepartmentsError);

  return (
    <>
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        {departments?.length > 0 && <DataGridContainer rows={departments} columns={columns} />}
      </Box>
      <Typography variant="body2" color="text.secondary" align="right">
        Συνολικά παραρτήματα: {departments?.length}
      </Typography>
      {departmentsError && <GenericModalError errorMessage={departmentsError} />}
    </>
  );
};

export default BranchesDataGrin;
