import { Grid } from '@mui/material';

import { useGenerateAccount } from '../../../../hooks/useAccountData';

import CustomTabPanel from '../../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../../components/FormFields/ViewOnlyTextInput';

type AddressesTabProps = {
  tab: number;
};

const AddressesTab = ({ tab }: AddressesTabProps) => {
  const account = useGenerateAccount();

  return (
    <CustomTabPanel value={tab} index={1}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Σταθερό Τήλ." name="telNum" value={account?.telNum} />
        </Grid>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Κινητό Τήλ." name="mobTel" value={account?.mobTel} />
        </Grid>
        <Grid item xs={4}>
          <ViewOnlyTextInput label="Διεύθυνση" name="businessAddress" value={account?.businessAddress} />
        </Grid>
        <Grid item xs={5}>
          <ViewOnlyTextInput label="Επάγγελμα" name="profession" value={account?.profession} />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default AddressesTab;
