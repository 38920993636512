import BasicTabs from './BasicTabs/BasicTabs';
import Layout from '../../hocs/Layout';
import Title from '../../components/Title/Title';

const BusinessData = () => {
  return (
    <Layout>
      <Title>Βασικά Στοιχεία</Title>
      <BasicTabs />
    </Layout>
  );
};

export default BusinessData;
