import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/es/constants';
import { Account } from '../../models/account';
import { getAccount } from '../../api/account';

interface IAccountState {
  accountData: any;
  error: string | null;
  loaded: boolean;
}

export const initialState: IAccountState = {
  accountData: null,
  error: null,
  loaded: false,
};

export const loadAccount = createAsyncThunk('account/getAccount', async () => await getAccount());

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAccount.pending, (state) => {
        state.error = null;
        state.loaded = false;
      })
      .addCase(loadAccount.fulfilled, (state, action) => {
        state.accountData = action.payload;
        state.loaded = true;
      })
      .addCase(loadAccount.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(PURGE, (state) => {
        state.accountData = null;
        state.error = null;
        state.loaded = false;
      });
  },
});

export default accountSlice.reducer;
