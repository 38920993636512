import { FieldValues, UseControllerProps } from 'react-hook-form';

import { TextField } from '@mui/material';

type TextInputProps<T extends FieldValues> = {
  label: string;
  isNumber?: boolean;
  value?: string | number;
} & UseControllerProps<T>;

const ViewOnlyTextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const { label, name, isNumber, value } = props;

  return (
    <TextField
      multiline
      disabled
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
          color: 'black',
        },
      }}
      inputProps={{ readOnly: true }}
      margin="dense"
      fullWidth
      id={name}
      label={label}
      name={name}
      value={value}
      autoFocus
      type={isNumber ? 'number' : 'text'}
      InputLabelProps={{shrink: true}}
    />
  );
};

export default ViewOnlyTextInput;
