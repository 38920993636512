import { jwtDecode } from 'jwt-decode';

interface IJWTToken {
  exp?: number;
}

export const isTokenExpired = (token: string) => {
  if (!token) return true;

  try {
    const decodedToken = jwtDecode<IJWTToken>(token);
    const currentTime = Date.now() / 1000;

    return (decodedToken.exp ?? 0) < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};