import { ReactNode } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';

import { DropDownOptions } from '../../typings';

type DropDownListProps = {
  isDisabled?: boolean;
  options?: DropDownOptions[];
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent, child: ReactNode) => void;
  sxProps?: SxProps<Theme> | undefined
};

const DropDownList = ({ label, onChange, value, options, isDisabled = false, sxProps }: DropDownListProps) => (
  <FormControl sx={sxProps} disabled={isDisabled} fullWidth>
    <InputLabel id="simple-select-label">{label}</InputLabel>
    <Select
      labelId="simple-select-label"
      id="simple-select"
      value={value}
      onChange={onChange}
      label="Επιλέξτε τύπο εργασίας"
    >
      {options?.map((item, index) => (
        <MenuItem key={`${item}-item-${index}`} value={item.value}>{item.label}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default DropDownList;