import './DetailsContent.scss';

type DetailsContentProps = {
  label: string;
  from?: string;
  to?: string;
}

const DetailsContent = ({ label, from = '', to = '' }: DetailsContentProps) => (
  <div className="details-content">
    <div className="details-content__label">{label}:</div>
    <div className="details-content__range">
      {from} - {to}
    </div>
  </div>
);


export default DetailsContent;
