import Title from '../../components/Title/Title';
import Layout from '../../hocs/Layout';
import BranchesDataGrin from './styledComponents/BranchesDataGrid';

const Branches = () => {
  return (
    <Layout>
      <Title>Παραρτήματα</Title>
      <BranchesDataGrin />
    </Layout>
  );
};

export default Branches;
