import { Grid } from '@mui/material';

import CustomTabPanel from '../../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../../components/FormFields/ViewOnlyTextInput';

import { useGenerateAccount } from '../../../../hooks/useAccountData';

type CredentialsTabProps = {
  tab: number;
};

const StatisticsTab = ({ tab }: CredentialsTabProps) => {
  const account = useGenerateAccount();

  return (
    <CustomTabPanel value={tab} index={4}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ViewOnlyTextInput label="Παραρτήματα" name="departments" value={account?.departments} />
        </Grid>
        <Grid item xs={3}>
          <ViewOnlyTextInput label="Εργαζόμενοι" name="employees" value={account?.employees} />
        </Grid>
        <Grid item xs={3}>
          <ViewOnlyTextInput label="Check-ins Σήμερα" name="checkinsToday" value={account?.checkinsToday} />
        </Grid>
        <Grid item xs={3}>
          <ViewOnlyTextInput label="Check-outs Σήμερα" name="checkoutsToday" value={account?.checkoutsToday} />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default StatisticsTab;
