import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/es/constants';
import { getCalendar, setCalendar } from '../../api/calendar';
import { ICalendar, ICalendarDTO } from '../../models/calendar';

interface ICalendarState {
  setCalendarData: any; // possible will be removed if not needed in future
  calendarData: ICalendar[] | null;
  error: string | null;
  calendarDataError: string | null;
  loaded: boolean;
}

export const initialState: ICalendarState = {
  setCalendarData: null,
  calendarData: null,
  error: null,
  calendarDataError: null,
  loaded: false,
};

export const setCalendarAction = createAsyncThunk('calendar/setCalendar', async (data: any) => await setCalendar(data));

export const getCalendarAction = createAsyncThunk('calendar/getCalendar', async (data: ICalendarDTO) => await getCalendar(data));

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCalendarAction.pending, (state) => {
        state.error = null;
      })
      .addCase(setCalendarAction.fulfilled, (state, action) => {
        state.setCalendarData = action.payload;
        state.loaded = true;
        state.error = null;
      })
      .addCase(setCalendarAction.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(getCalendarAction.pending, (state) => {
        state.calendarDataError = null;
      })
      .addCase(getCalendarAction.fulfilled, (state, action) => {
        state.calendarData = action.payload;
        state.loaded = true;
        state.calendarDataError = null;
      })
      .addCase(getCalendarAction.rejected, (state, action) => {
        state.calendarDataError = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(PURGE, (state) => {
        state.calendarData = null;
        state.calendarDataError = null;
        state.error = null;
        state.setCalendarData = null;
        state.loaded = false;
      });
  },
});

export default calendarSlice.reducer;
