import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Send } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import Layout from '../../hocs/Layout';
import { getOneMonthLater } from '../../helpers/date.helper';
import Title from '../../components/Title/Title';
import DropDownList from '../../components/FormFields/DropDownList';
import EmployeeTable from './components/EmployeeTable';
import { FormModel, initValues, initValuesDaily } from './formModel';
import { DaysType, EJobTypes, TimeRangeType } from './typings';
import { jobTypeOptions, programTypeOptions, useGenerateBranchesOptions } from './optionsData';
import { useAppDispatch, useAppSelector } from '../../store/app-hooks';
import { setCalendarAction } from '../../store/calendar/calendarSlice';
import { selectSetCalendarError } from '../../store/calendar/selectors';
import GenericModalError from '../../components/Modal/GenericModalError';
import { selectEmployeeError } from '../../store/employee/selectors';
import { mapFormValuesToWTOS } from '../../helpers/mappings.helper';

const ApplicationForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const setCalendarError = useAppSelector(selectSetCalendarError);
  const employeeError = useAppSelector(selectEmployeeError);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [programType, setProgramType] = useState<string>('weekly');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(getOneMonthLater());
  const notHours = ['ΜΕ', 'ΑΝ'];

  const branchesOptions = useGenerateBranchesOptions();

  const [formValues, setFormValues] = useState<FormModel>(initValues);

  const handleJobTypeChange = (day: DaysType, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        type: value,
      },
    }));
  };

  const handleTimeChange = (day: DaysType, index: number, field: keyof TimeRangeType, newValue: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        WTO: prevState[day].WTO.map((timeRange, i) =>
          i === index ? { ...timeRange, [field]: newValue?.format('HH:mm') } : timeRange,
        ),
      },
    }));
  };

  const handleBranchChange = (day: DaysType, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        departmentID: value,
      },
    }));
  };

  const handleAddRow = (day: DaysType) => {
    setFormValues((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        WTO: [...prevState[day].WTO, { f_from: dayjs(), f_to: dayjs() }],
      },
    }));
  };

  const handleRemoveRow = (day: DaysType) => {
    setFormValues((prevState) => {
      const updatedTimeRange = [...prevState[day].WTO];
      updatedTimeRange.pop();
      return {
        ...prevState,
        [day]: {
          ...prevState[day],
          WTO: updatedTimeRange,
        },
      };
    });
  };

  const setCalendar = () => {
    const payload = {
      schedule_type: programType,
      from_date: startDate?.format('DD/MM/YYYY'),
      to_date: endDate?.format('DD/MM/YYYY'),
      employees: rowSelectionModel,
      wtos: mapFormValuesToWTOS(formValues),
    };
    dispatch(setCalendarAction(payload));
  };

  useEffect(() => {
    setFormValues(programType === 'daily' ? (initValuesDaily as FormModel) : initValues);
  }, [programType]);

  return (
    <Layout>
      <Title>Αίτηση εντύπου εργασίας</Title>
      <Grid item container columnSpacing={1} spacing={1} marginBottom={2} justifyContent="center">
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
            <DatePicker
              minDate={dayjs()}
              onChange={(newValue) => setStartDate(newValue)}
              format="DD/MM/YYYY"
              value={startDate}
              label="Από"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
            <DatePicker
              minDate={dayjs()}
              onChange={(newValue) => setEndDate(newValue)}
              format="DD/MM/YYYY"
              value={endDate}
              label="Έως"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <DropDownList
            label="Τύπος προγράμματος"
            options={programTypeOptions}
            value={programType}
            onChange={(e) => setProgramType(e.target.value)}
          />
        </Grid>
      </Grid>

      <EmployeeTable setRows={setRowSelectionModel} />

      {rowSelectionModel.length > 0 && (
        <>
          <Grid container columnSpacing={1} spacing={1} marginTop={3} justifyContent="space-between">
            <Grid item xl={2}>
              <Button
                variant="contained"
                onClick={setCalendar}
                disabled={mapFormValuesToWTOS(formValues).length === 0}
                endIcon={<Send />}
              >
                Αποστολη
              </Button>
            </Grid>
          </Grid>
          <Grid container marginTop={2} spacing={3}>
            {Object.keys(formValues).map((item, index) => {
              const day = item as DaysType;

              const disabledInput = formValues[day].type === 'ΚΑΜ';
              const disabledAddIcon = formValues[day].WTO.length >= 3;
              const disabledRemoveIcon = formValues[day].WTO.length === 1;

              return (
                <Grid key={`${item}-day-${index}`} item xs={12} md={6} lg={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {programType === 'daily' ? 'Ημερήσιο' : t(item)}
                    </Grid>
                    <Grid item xs={12}>
                      <DropDownList
                        label="Τύπος Εργασίας"
                        options={jobTypeOptions}
                        value={formValues[day].type}
                        onChange={(e) => handleJobTypeChange(day, e.target.value as EJobTypes)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DropDownList
                        label="Παράρτημα"
                        options={branchesOptions}
                        onChange={(e) => handleBranchChange(day, e.target.value)}
                        value={formValues[day].departmentID}
                        isDisabled={disabledInput}
                      />
                    </Grid>
                    {!notHours.includes(formValues[day].type) &&
                      formValues[day].WTO.map((timeRange, index) => (
                        <Fragment key={`${item}-time-${index}`}>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                ampm={false}
                                disabled={disabledInput}
                                label="Από"
                                value={timeRange.f_from}
                                onChange={(newValue) => newValue && handleTimeChange(day, index, 'f_from', newValue)}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                ampm={false}
                                disabled={disabledInput}
                                label="Έως"
                                value={timeRange.f_to}
                                onChange={(newValue) => newValue && handleTimeChange(day, index, 'f_to', newValue)}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Fragment>
                      ))}
                    {!notHours.includes(formValues[day].type) && (
                      <Grid item xs={12}>
                        <IconButton
                          disabled={disabledInput || disabledRemoveIcon}
                          color="info"
                          aria-label="remove"
                          onClick={() => handleRemoveRow(day)}
                        >
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton
                          disabled={disabledInput || disabledAddIcon}
                          color="info"
                          aria-label="add"
                          onClick={() => handleAddRow(day)}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      <GenericModalError errorMessage={employeeError || setCalendarError} />
    </Layout>
  );
};

export default ApplicationForm;
