import { useParams } from 'react-router';

import { Box, Divider, Grid } from '@mui/material';

import CustomTabPanel from '../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../components/FormFields/ViewOnlyTextInput';
import { useDepartmentsData } from '../../../hooks/useDepartmentsData';
import { useEmployeesData } from '../../../hooks/useApplicationData';

type EmployeeInfoProps = {
  tab: number;
};

const PrimaryInfo = ({ tab }: EmployeeInfoProps) => {
  const { id } = useParams();
  const [departments, departmentsLoaded] = useDepartmentsData();
  const [employees] = useEmployeesData();

  const currentEmployee = employees?.find(({ employeeID }) => employeeID.toString() === id);

  if (!currentEmployee || !departmentsLoaded) return null;

  const branch = departments?.find(dep => dep.departmentID === currentEmployee?.departmentID)?.description;

  return (
    <CustomTabPanel value={tab} index={0}>
      <Box component="article" sx={{ mt: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Επώνυμο" name="surnname" value={currentEmployee.surname} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Όνομα" name="firstName" value={currentEmployee.name} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Όνομα Πατέρα" name="fatherName" value={currentEmployee.fathername} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="ΑΦΜ" name="afm" value={currentEmployee.afm} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="ΑΜΚΑ" name="amka" value="" />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Παράρτημα" name="amka" value={branch} />
          </Grid>
        </Grid>
        <Divider color="black" sx={{ my: 3 }} />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Οδός" name="streetName" value={currentEmployee.address} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Αριθμός" name="streetNumber" value="" />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Πόλη" name="city" value={currentEmployee.city} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Νομός" name="nomos" value="" />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Ταχυδρομικός Κώδικας" name="postal" value={currentEmployee.postal} />
          </Grid>
        </Grid>
        <Divider color="black" sx={{ my: 3 }} />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Τηλέφωνο" name="phone" value="" />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Κινητό" name="mobilePhone" value={currentEmployee.mobile} />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="Fax" name="fax" value="" />
          </Grid>
          <Grid item xs={4}>
            <ViewOnlyTextInput label="email" name="email" value={currentEmployee.email} />
          </Grid>
        </Grid>
      </Box>
    </CustomTabPanel>
  );
};

export default PrimaryInfo;
