export enum Translations {
  // Login
  LOGIN_BUTTON = 'loginButton',
  CONNECT_TO_YOUR_ACCOUNT = 'connectToYourAccount',
  WELCOME_TITLE = 'welcomeTitle',
  USERNAME = 'username',
  PASSWORD = 'password',
  REMEMBER_ME = 'rememberMe',
  FORGOT_PASSWORD = 'forgotPassword',
  REGISTER_HERE = 'registerHere',

  // Global Sidebar
  LANDING_PAGE = 'landingPage',
  BUSINESS_DATA = 'businessData',
  BRANCHES = 'branches',
  EMPLOYEES = 'employees',
  CARD_MOVEMENTS = 'cardMovements',
  CALENDAR = 'calendar',
  APPLICATION_FORM = 'applicationForm',

  // App bar
  SEARCH = 'search',

  //Basic tabs 
  BASIC_DATA = 'basicData',
  ADDRESSES = 'addresses',
  CREDENTIALS = 'credentials',
  TAX_DATA = 'taxData',
  STATISTICS = 'statistics',
}
