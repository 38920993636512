import { ReactNode } from 'react';

import Typography from '@mui/material/Typography';

interface TitleProps {
  children?: ReactNode;
}

const Title = (props: TitleProps) => {
  return (
    <Typography component="h2" variant="h6" color="primary" sx={{mb: 2}}>
      {props.children}
    </Typography>
  );
};

export default Title;
