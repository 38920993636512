import * as yup from 'yup';

export interface ILoginForm {
  username: string;
  password: string;
  rememberMe: boolean;
}

export const initialForm: ILoginForm = {
  username: '',
  password: '',
  rememberMe: false,
};

export const validationSchema = yup.object({
  username: yup.string().required('Υποχρεωτικό πεδίο'),
  password: yup.string().required('Υποχρεωτικό πεδίο').min(8, 'Ο κωδικός δεν μπορεί να είναι μικρότερος από 8 ψηφία'),
  rememberMe: yup.boolean().required(),
});
