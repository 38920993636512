import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { Translations } from '../../../i18n/translationKeys';

import BasicInfoTab from './styledComponents/BasicInfoTab';
import AddressesTab from './styledComponents/AddressesTab';
import TaxTab from './styledComponents/TaxTab';
import StatisticsTab from './styledComponents/StatisticsTab';
import CredentialsTab from './styledComponents/CredentialsTab/CredentialsTab';
import { useHandleTabs } from '../../../hooks/useHandleTabs';
import { TabData } from '../../../typings';
import TabWrapper from '../../../components/TabWrapper/TabWrapper';
import { selectAccountError } from '../../../store/account/selectors';
import GenericModalError from '../../../components/Modal/GenericModalError';
import { useAppSelector } from '../../../store/app-hooks';

const BasicTabs = () => {
  const { t } = useTranslation();

  const accountError = useAppSelector(selectAccountError);
  const { tab, handleChange } = useHandleTabs();

  const tabsData: TabData[] = [
    {
      label: t(Translations.BASIC_DATA),
    },
    {
      label: t(Translations.ADDRESSES),
    },
    {
      label: t(Translations.TAX_DATA),
    },
    {
      label: t(Translations.CREDENTIALS),
    },
    {
      label: t(Translations.STATISTICS),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabWrapper tabValue={tab} handleChange={handleChange} tabsData={tabsData} />
      </Box>
      <Box component="form" sx={{ mt: 1 }}>
        <BasicInfoTab tab={tab} />
        <AddressesTab tab={tab} />
        <TaxTab tab={tab} />
        <CredentialsTab tab={tab} />
        <StatisticsTab tab={tab} />
      </Box>
      {accountError && <GenericModalError errorMessage={accountError} />}
    </Box>
  );
};
export default BasicTabs;
