import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../store/app-hooks';
import { selectAuthToken, selectAuthUser } from '../store/auth/selectors';
import { isTokenExpired } from '../helpers/token.helper';

type ProtectedRouteProps = {
  element: ReactElement;
};

const ProtectedRoute = ({ element }: ProtectedRouteProps) => {
  const user = useAppSelector(selectAuthUser);
  const authToken = useAppSelector(selectAuthToken);

  const tokenExpired = authToken ? isTokenExpired(authToken) : false;

  if (!user?.access_token || tokenExpired) {
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
