import { useState, useEffect } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '1px solid #7f7f7f',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4,
};

type GenericModalErrorProps = {
  errorMessage?: string | null ;
  failedmsg?:string | null ;
  errmsg?:string | any ;
  status?: string;
  clearAction?: () => void;
  modaltype?: string | null;
  loaderVisible?: boolean;
};

const GenericModal: React.FC<GenericModalErrorProps> = (props) => {
  const { errorMessage, clearAction,errmsg, loaderVisible, modaltype,failedmsg } = props;

  const [open, setOpen] = useState(errorMessage !== null);
  const [hidebackdrop, sethidebacdrop] = useState(false);

  useEffect(() => {
    setOpen(errorMessage !== null);
  }, [errorMessage]);


  const handleClose = () => {
    if (!loaderVisible) {
      clearAction && clearAction();
      setOpen(false);
    }
  };

  const hidedrop=()=>{
if(!loaderVisible){
  sethidebacdrop(true)
}else{
  sethidebacdrop(false)
}
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      hideBackdrop={hidebackdrop}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2" align="center">
            {loaderVisible && 'Παρακαλώ περιμένετε' }
            {errmsg.msg && 'επιτυχία'} 
            {!loaderVisible && !errmsg.msg &&  ' Σφάλμα'} 
          </Typography>
          {loaderVisible ?
            <CircularProgress
              sx={{ marginTop: 4, marginBottom: 4, marginLeft: 18 }} />
            :
            <Typography align="center" id="transition-modal-description" sx={{ mt: 2 }}>
               {errmsg.msg && errmsg.msg  }
               {failedmsg && failedmsg }
            </Typography>
          }
          {modaltype && !loaderVisible &&
            <Button
              onClick={handleClose}
              sx={{ marginTop: 4, marginLeft: 16 }}
              variant="contained">
              Κλείσε
            </Button>}
        </Box>
      </Fade>
    </Modal>
  );
};

export default GenericModal;
