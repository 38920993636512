import dayjs from 'dayjs';
import { DaysType, JobInfoType } from './typings';

type FormModelDaily = {
  '0': JobInfoType;
};

export type FormModel = FormModelDaily & Record<Exclude<DaysType, '0'>, JobInfoType>;

export const commonValues = {
  type: 'ΚΑΜ',
  departmentID: '0',
  WTO: [
    {
      f_from: dayjs().format('HH:mm'),
      f_to: dayjs().format('HH:mm'),
    },
  ],
};

export const initJobInfoValueMonday: JobInfoType = {
  f_day: '1',
  ...commonValues,
};

export const initJobInfoValueTuesday: JobInfoType = {
  f_day: '2',
  ...commonValues,
};

export const initJobInfoValueWednesday: JobInfoType = {
  f_day: '3',
  ...commonValues,
};

export const initJobInfoValueThursday: JobInfoType = {
  f_day: '4',
  ...commonValues,
};

export const initJobInfoValueFriday: JobInfoType = {
  f_day: '5',
  ...commonValues,
};

export const initJobInfoValueSaturday: JobInfoType = {
  f_day: '6',
  ...commonValues,
};

export const initJobInfoValueSunday: JobInfoType = {
  f_day: '0',
  ...commonValues,
};

export const initValues: FormModel = {
  '1': initJobInfoValueMonday,
  '2': initJobInfoValueTuesday,
  '3': initJobInfoValueWednesday,
  '4': initJobInfoValueThursday,
  '5': initJobInfoValueFriday,
  '6': initJobInfoValueSaturday,
  '0': initJobInfoValueSunday,
};

export const initValuesDaily: FormModelDaily = {
  '0': initJobInfoValueSunday,
};
