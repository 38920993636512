import { apiClient } from './apiClient';
import { ICalendar, ICalendarDTO } from '../models/calendar';

export const setCalendar = async (data: any): Promise<any> => {
  try {
    const response = await apiClient.post<any[]>('/set_calendar', data);

    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getCalendar = async (data: ICalendarDTO): Promise<ICalendar[]> => {
  try {
    const response = await apiClient.post<ICalendar[]>('/get_calendar', data);

    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};