import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { el } from './locales/el';
import { en } from './locales/en';

const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  el: {
    translation: {
      ...el,
    },
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  fallbackLng: 'el',
  lng: 'el', // This will change dynamically if the want two languages
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;