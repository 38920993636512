import { RouterProvider } from 'react-router-dom';
import router from './routes/Router';

import './App.scss';

const App = () => {
  return (
    <div className="App">
      <header>
        <RouterProvider router={router} />
      </header>
    </div>
  );
};

export default App;
