import { Grid } from '@mui/material';

import CustomTabPanel from '../../../../components/TabWrapper/CustomTabPanel';
import ViewOnlyTextInput from '../../../../components/FormFields/ViewOnlyTextInput';

import { useGenerateAccount } from '../../../../hooks/useAccountData';

type CredentialsTabProps = {
  tab: number;
};

const TaxTab = ({ tab }: CredentialsTabProps) => {
  const account = useGenerateAccount();
  
  return (
    <CustomTabPanel value={tab} index={2}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <ViewOnlyTextInput label="Δ.Ο.Υ." name="doy" value={account?.doy} />
        </Grid>
        <Grid item xs={5}>
          <ViewOnlyTextInput label="Αρ. Γε.Μη." name="argemi" value={account?.argemi} />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default TaxTab;
