import { useState, useEffect } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '1px solid #7f7f7f',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4,
};

type GenericModalErrorProps = {
  errorMessage: string | null;
  status?: string;
  clearAction?: () => void;
};

const GenericModalError: React.FC<GenericModalErrorProps> = (props) => {
  const { errorMessage, clearAction } = props;

  const [open, setOpen] = useState(errorMessage !== null);

  useEffect(() => {
    setOpen(errorMessage !== null);
  }, [errorMessage]);


  const handleClose = () => {
    clearAction && clearAction();
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2" align="center">
            Σφάλμα
          </Typography>
          <Typography align="center" id="transition-modal-description" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default GenericModalError;