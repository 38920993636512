import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/es/constants';
import { IDepartment } from '../../models/IDepartment';
import { getDepartments } from '../../api/department';

interface IDepartmentState {
  departmentsData: IDepartment[] | null;
  error: string | null;
  loaded: boolean;
}

export const initialState: IDepartmentState = {
  departmentsData: null,
  error: null,
  loaded: false,
};

export const loadDepartments = createAsyncThunk('departments/getDepartments', async () => await getDepartments());

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadDepartments.pending, (state) => {
        state.error = null;
        state.loaded = false;
      })
      .addCase(loadDepartments.fulfilled, (state, action) => {
        state.departmentsData = action.payload;
        state.loaded = true;
      })
      .addCase(loadDepartments.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(PURGE, (state) => {
        state.departmentsData = null;
        state.error = null;
        state.loaded = false;
      });
  },
});

export default departmentsSlice.reducer;
