import { IEmployee_Sync } from '../models/employee';
import { apiClient } from './apiClient';

export const getEmployees_sync = async (): Promise<IEmployee_Sync[]> => {
  try {
    const response = await apiClient.get<IEmployee_Sync[]>('/employees_sync_ergani');
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};