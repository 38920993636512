import { ICardAction, ICardActionDTO } from '../../models/cardActions';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCardActions } from '../../api/cardActions';
import { PURGE } from 'redux-persist/es/constants';

interface ICardActionState {
  cardActionsData: ICardAction[] | null;
  error: string | null;
  loaded: boolean;
}

export const initialState: ICardActionState = {
  cardActionsData: null,
  error: null,
  loaded: false,
};

export const getCardActions_Action = createAsyncThunk('cardActions/getCardActions', async (data: ICardActionDTO) => await getCardActions(data));

export const cardActionsSlice = createSlice({
  name: 'cardActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCardActions_Action.pending, (state) => {
        state.error = null;
        state.loaded = false;
      })
      .addCase(getCardActions_Action.fulfilled, (state, action) => {
        state.cardActionsData = action.payload;
        state.loaded = true;
      })
      .addCase(getCardActions_Action.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
        state.loaded = true;
      })
      .addCase(PURGE, (state) => {
        state.cardActionsData = null;
        state.error = null;
        state.loaded = false;
      });
  },
});

export default cardActionsSlice.reducer;
