import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import { ArrowBack } from '@mui/icons-material';

import Layout from '../../hocs/Layout';
import PrimaryInfo from './components/PrimaryInfo';
import CardMovements from './components/CardMovements';
import { useHandleTabs } from '../../hooks/useHandleTabs';
import { TabData } from '../../typings';
import TabWrapper from '../../components/TabWrapper/TabWrapper';

const tabsData: TabData[] = [{ label: 'Βασικές Πληροφορίες' }, { label: 'Κινήσεις Κάρτας' }];

const EmployeeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { tab, handleChange } = useHandleTabs();

  const handleGoBack = () => navigate(-1);

  return (
    <Layout>
      <ArrowBack onClick={handleGoBack} className="arrowBackButton" />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabWrapper tabValue={tab} handleChange={handleChange} tabsData={tabsData} />
      </Box>
      <Box component="div" sx={{ mt: 1 }}>
        <PrimaryInfo tab={tab} />
        <CardMovements tab={tab} employeeId={params.id} />
      </Box>
    </Layout>
  );
};

export default EmployeeDetails;
