import { apiClient } from './apiClient';
import { ICardAction, ICardActionDTO } from '../models/cardActions';

export const getCardActions = async (data: ICardActionDTO): Promise<ICardAction[]> => {
  try {
    const response = await apiClient.post<ICardAction[]>('/get_card_actions', data);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};