import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { ILoginForm, initialForm } from './formModel';
import myErgani from '../../assets/img/my-ergani.png';

import { Translations } from '../../i18n/translationKeys';
import Copyright from '../../components/Copyright/Copyright';
import ModalError from './styledComponents/ModalError';
import { useAppDispatch, useAppSelector } from '../../store/app-hooks';
import { clearUser, loginAction } from '../../store/auth/authSlice';
import { selectAuthUser } from '../../store/auth/selectors';

import './Login.scss';

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const user = useAppSelector(selectAuthUser);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginForm>({
    // resolver: yupResolver(validationSchema),
    defaultValues: initialForm,
  });

  const onSubmit = (data: ILoginForm) => {
    const payload = {
      username: data.username,
      password: data.password,
    };
    dispatch(loginAction(payload)).then(() => navigate('/businessData'));
  };

  useEffect(() => {
    if (user?.access_token) {
      dispatch(clearUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="form-wrapper" component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
      <CssBaseline />
      <img
        src={myErgani}
        style={{
          width: '100%',
          height: 'auto',
          alignItems: 'center',
        }}
        alt="logo of egrani app"
      />
      <Typography component="h5" variant="h6" className="d-flex justify-content-center font-weight-bold mt-3">
        {t(Translations.WELCOME_TITLE)}
      </Typography>
      <Typography component="h5" variant="subtitle2" className="d-flex justify-content-center">
        {t(Translations.CONNECT_TO_YOUR_ACCOUNT)}
      </Typography>
      <ModalError />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.username)}
              helperText={errors.username?.message}
              margin="normal"
              required
              fullWidth
              id="username"
              label={t(Translations.USERNAME)}
              name="username"
              autoComplete="username"
              autoFocus
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t(Translations.PASSWORD)}
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  paddingRight: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <Button
                      onClick={() => setShowPassword(!showPassword)}
                      color="primary"
                      size="small"
                      sx={{ mt: 0.5 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </Button>
                  </>
                ),
              }}
            />
          )}
        />
        <Controller
          name="rememberMe"
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox {...field} color="primary" />} label={t(Translations.REMEMBER_ME)} />
          )}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {t(Translations.LOGIN_BUTTON)}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              {t(Translations.FORGOT_PASSWORD)}
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {t(Translations.REGISTER_HERE)}
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Copyright sx={{ mt: 5, mb: 2 }} />
    </Container>
  );
};

export default Login;
