import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/el';

import { GridColDef } from '@mui/x-data-grid';
import { Box, Button, Grid } from '@mui/material';
import { Download } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { saveAs } from 'file-saver';

import Layout from '../../hocs/Layout';
import Title from '../../components/Title/Title';
import DataGridContainer from '../../components/DataGridContainer/DataGridContainer';

import { getOneMonthBack, convertDateToLocalFormat} from '../../helpers/date.helper';
import { useAppDispatch } from '../../store/app-hooks';
import { ICardActionDTO } from '../../models/cardActions';
import { getCardActions_Action } from '../../store/cardAction/cardActionSlice';
import { useProduceCardMovementData } from '../../hooks/useProduceCardMovementData';

const columns: GridColDef[] = [
  { field: 'afm', headerName: 'ΑΦΜ', flex: 1 },
  {
    field: 'surname',
    headerName: 'Επώνυμο',
    flex: 1,
  },
  { field: 'name', headerName: 'Όνομα', flex: 1 },
  { field: 'branch', headerName: 'Παράρτημα', flex: 1 },
  { field: 'cardType', headerName: 'Τύπος', flex: 1 },
  {
    field: 'actionDate',
    headerName: 'Ημ. Ενέργειας',
    flex: 1,
    renderCell: (param) => <>{(param.row.actionDate).toLocaleString('en-GB')}</>,
  },
  {
    field: 'state',
    headerName: 'Κατάσταση',
    flex: 1,
  },
];

const CardMovements = () => {
  const dispatch = useAppDispatch();
  const cardMovementData = useProduceCardMovementData();

  const [startDate, setStartDate] = useState<Dayjs | null>(getOneMonthBack());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const sendCardActionsRequest = (start: Date, end: Date) => {
    const payLoad: ICardActionDTO = {
      FromDate: start.toLocaleDateString('en-GB'),
      ToDate: end.toLocaleDateString('en-GB'),
    };

    dispatch(getCardActions_Action(payLoad));
  };

  // console.log('card movements',cardMovementData)
  useEffect(() => {
    if (startDate && endDate) {
      sendCardActionsRequest(startDate.toDate(), endDate.toDate());
    }
  }, [startDate, endDate]);

  const rowsFinal = cardMovementData?.map((rowItem: any) => ({
    ...rowItem,
    actionDate: rowItem.actionDate,
  }));
  // console.log('cardMovementData',rowsFinal)

  const handleExportToExcel = () => {
    import('xlsx').then((xlsx) => {
      const formattedData = rowsFinal.map((rowItem:any) => ({
        ...rowItem,
        actionDate: new Date(rowItem.actionDate).toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }), 
      }));
      const worksheet = xlsx.utils.json_to_sheet(formattedData);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        `ΚινήσειςΚαρτών_${startDate?.format('DD-MM-YY')}_${endDate?.format('DD-MM-YY')}.xlsx`,
      );
    });
  };

  const handleFromDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    newValue && endDate && sendCardActionsRequest(newValue.toDate(), endDate.toDate());
  };

  const handleToDate = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    newValue && startDate && sendCardActionsRequest(startDate.toDate(), newValue.toDate());
  };

  return (
    <Layout>
      <Grid container spacing={3} alignItems="center">
        <Grid item flex={0.3}>
          <Title>Κινήσεις Κάρτας</Title>
        </Grid>
        <Grid item flex={0.7} container columnSpacing={1} spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              color="success"
              style={{ height: '100%', borderRadius: 50 }}
              variant="contained"
              onClick={handleExportToExcel}
            >
              EXCEL <Download />
            </Button>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
              <DatePicker
                onChange={handleFromDate}
                format="DD/MM/YYYY"
                value={startDate}
                label="Από"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
              <DatePicker
                onChange={handleToDate}
                format="DD/MM/YYYY"
                value={endDate}
                label="Έως"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {cardMovementData.length > 0 && (
            <Box sx={{ height: 'calc(100vh - 200px)', width: '100%', overflowY: 'auto' }}>
              <DataGridContainer
                rows={rowsFinal}
                columns={columns}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CardMovements;
