import { apiClient } from './apiClient';
import { IUser, IUserDTO } from '../models/user';

export const login = async (data: IUserDTO): Promise<IUser> => {
  try {
    const response = await apiClient.post<IUser>('/login', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
