import { SyntheticEvent, useState } from 'react';

export const useHandleTabs = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (e: SyntheticEvent, newValue: number) => {
    e.preventDefault();
    setTab(newValue);
  };

  return { tab, handleChange };
};
