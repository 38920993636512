import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { useEmployeesData } from '../../../hooks/useApplicationData';
import DataGridContainer from '../../../components/DataGridContainer/DataGridContainer';

type EmployeeTableProps = {
  setRows: Function;
};
const EmployeeTable = ({ setRows }: EmployeeTableProps) => {
  const [employeesData, employeesLoaded] = useEmployeesData();

  const columns: GridColDef[] = [
    {
      field: 'surname',
      headerName: 'Επώνυμο',
      flex: 1,
    },
    { field: 'name', headerName: 'Ονομα', flex: 1 },
    { field: 'afm', headerName: 'ΑΦΜ', flex: 1 },
    // { field: 'status', headerName: 'Κατάσταση', flex: 1 },
  ];

  if (!employeesLoaded) return null;

  const employeesRows = employeesData
    ? employeesData!.map((empData) => ({
        id: empData.employeeID,
        name: empData.name,
        surname: empData.surname,
        afm: empData.afm,
        // status: empData.status === 1 ? 'Ενεργός' : 'Ανενεργός',
      }))
    : [];

  const setSelectedEmployeesAfm = (rows: GridRowSelectionModel) => {
    const employeesIds = rows.map((rowId) => {
      return employeesRows.find((item) => item.id === rowId)?.id;
    });
    setRows(employeesIds);
  };

  return (
    <Box sx={{ height: 300, width: '100%', overflowY: 'auto' }}>
      <DataGridContainer
        rows={employeesRows}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => setSelectedEmployeesAfm(newRowSelectionModel)}
      />
    </Box>
  );
};

export default EmployeeTable;
