import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import authSlice from './auth/authSlice';
import basicInfoSlice from './basicInfo/basicInfoSlice';
import employeeSlice from './employee/employeeSlice';
import calendarSlice from './calendar/calendarSlice';
import cardActionsSlice from './cardAction/cardActionSlice';
import departmentsSlice from './department/departmentSlice';
import accountSlice from './account/accountSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  account: accountSlice,
  auth: authSlice,
  basicInfo: basicInfoSlice,
  employees: employeeSlice,
  calendar: calendarSlice,
  cardActions: cardActionsSlice,
  departments: departmentsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
