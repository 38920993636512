import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { elGR } from '@mui/x-data-grid/locales';

const DataGridContainer = (props: DataGridProps) => (
  <DataGrid
  pagination
  autoPageSize
    localeText={elGR.components.MuiDataGrid.defaultProps.localeText}
    {...props}
  />
);

export default DataGridContainer;