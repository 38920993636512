import dayjs, { Dayjs } from 'dayjs';

export const getOneMonthBack = () => {
  const oneMonthAgo = dayjs().subtract(1, 'month');
  return dayjs(oneMonthAgo);
};

export const getOneMonthLater = () => {
  const oneMonthLater = dayjs().add(1, 'month');
  return dayjs(oneMonthLater);
};

export const convertDayJStoDate = (currentDayJs: Dayjs) => currentDayJs.toDate();

export const convertDateToDayJs = (currentDate: Date) => dayjs(currentDate);

export const checkDateRange = (startDate: Dayjs, endDate: Dayjs, currentDate: Dayjs) => {
  const isSameDay = currentDate.isSame(startDate, 'day') || currentDate.isSame(endDate, 'day');
  const isBetween = currentDate.isAfter(startDate) && currentDate.isBefore(endDate);

  return isSameDay || isBetween;
};


export const convertDateToLocalFormat = (date: Date) => {
  // avoid setting number of day  to "32"
  const dayOfMonth = date.getUTCDate();

  // Extract the day, month, and year
  const day = String(dayOfMonth).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  const year = date.getUTCFullYear();

  // Format the date as DD/MM/YYYY
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date as DD/MM/YYYY HH:mm
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
