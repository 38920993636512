import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/app-hooks';
import moment from 'moment';
import 'moment/locale/el';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material';
import { Calendar as MyCal, momentLocalizer, View } from 'react-big-calendar';

import Title from '../../components/Title/Title';
import Layout from '../../hocs/Layout';
import DetailsModal from './components/DetailsModal';
import DropDownList from '../../components/FormFields/DropDownList';
import { mapToDropDownOptions } from '../../helpers/mappings.helper';
import { useEmployeesData } from '../../hooks/useApplicationData';
import { DateType, ICalendarDTO } from '../../models/calendar';
import { getCalendarAction, setCalendarAction } from '../../store/calendar/calendarSlice';
import { useGenerateCalendarEvents } from '../../hooks/useGenerateCalendarEvents';
import { selectEmployeeError } from '../../store/employee/selectors';
import GenericModal from '../../components/Modal/GenericModal';
import { convertDateToLocalFormat } from '../../helpers/date.helper';

import './Calendar.scss';
import { getICalendar_Sync } from '../../api/calender_sync';
import GenericModalError from '../../components/Modal/GenericModalError';

interface ErrorResponse {
  message: string;
}

const Calendar = () => {
  const localizer = momentLocalizer(moment);
  const dispatch = useAppDispatch();
  const now = new Date();

  const [modalVisible, setModalVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [msgData, setMsgData] = useState([{}]);
  const [msgDataError, setMsgDataError] = useState<ErrorResponse | null>(null);

  const employeeError = useAppSelector(selectEmployeeError);

  const [employeesData, employeesLoaded] = useEmployeesData();
  const [open, setOpen] = useState(false);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [selectedEvent, setSelectedEvent] = useState({
    title: '',
    surname: '',
    start: Date.now(),
    end: Date.now(),
    backgroundColor: '#ffffff',
  });

  const filteredEvents = useGenerateCalendarEvents(selectedEmployeeId);

  // Initialize state with start and end dates of the current month
  const [dates, setDates] = useState({
    startDate: moment(now).startOf('month').startOf('week').toDate(),
    endDate: moment(now).endOf('month').endOf('week').toDate(),
  });

  if (!employeesLoaded) return null;

  const handleSelectEvent = (event: any) => {
    setOpen(true);
    setSelectedEvent(event);
  };

  const eventStyleGetter = (event: any) => ({
    style: {
      backgroundColor: event.backgroundColor,
    },
  });

  const handleEmployeeChange = (event: SelectChangeEvent<string>) => {
    const employeeId = event.target.value;
    const { startDate, endDate } = dates;

    setSelectedEmployeeId(employeeId);
    createCalendarRequest(startDate, endDate, employeeId);
  };

  const handleRangeChange = (range: Date[] | DateType, view: View | undefined) => {
    if (view === 'month' || view === 'agenda') {
      const currDate = range as DateType;
      const start = currDate.start;
      const end = currDate.end;

      createCalendarRequest(start, end);
    }
    if (view === 'week') {
      const currDate = range as Date[];
      const start = currDate[0];
      const end = currDate[currDate.length - 1];

      createCalendarRequest(start, end);
    }
  };

  const handleNavigate = (date: Date, view: any) => {
    let start, end;

    if (view === 'month') {
      start = moment(date).startOf('month').startOf('week').toDate();
      end = moment(date).endOf('month').endOf('week').toDate();
    } else if (view === 'week') {
      start = moment(date).startOf('week').toDate();
      end = moment(date).endOf('week').toDate();
    } else {
      start = moment(date).subtract(1, 'days').toDate();
      end = moment(start).add(1, 'months').toDate();
    }

    createCalendarRequest(start, end);
  };


  const createCalendarRequest = (start: Date, end: Date, empId?: string) => {
    const currEmpId = empId || selectedEmployeeId;

    setDates(prev => ({
      ...prev,
      startDate: start,
      endDate: end,
    }));

    const calendarDto: ICalendarDTO = {
      EmployeeID: currEmpId,
      FromDate: convertDateToLocalFormat(start),
      ToDate: convertDateToLocalFormat(end),
    };

    dispatch(getCalendarAction(calendarDto));
  };

  const options = employeesData?.map(
    ({
       employeeID,
       afm,
       name,
       surname,
     }) => mapToDropDownOptions(afm, employeeID, `${name} ${surname} - ${afm}`));

     const handleFetchCalender = async () => {
      try {
        setModalVisible(true)
        setLoaderVisible(true)
        const response = await getICalendar_Sync();
        setLoaderVisible(false)
        setMsgData(response);
        // dispatch(getCalendarAction());
      } catch (error) {
        console.error('Error fetching employees:', error);
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
        setMsgDataError({ message: errorMessage });
      } finally {
        setLoaderVisible(false); // Hide loader
      }
    };
    const clearModal = () => {
      setModalVisible(false);
      setLoaderVisible(false);
      setMsgData([]); 
      setMsgDataError(null)
    };

  return (
    <Layout>
         <Button
          onClick={() => handleFetchCalender()}
          sx={{ marginBottom: 1 }}
          variant="contained">Ενημέρωση απο ΕΡΓΑΝΗ</Button>
      <Title>Ημερολόγιο εργασίας</Title>
      <div>
        <DropDownList
          sxProps={{ pb: 3, width: '50%' }}
          options={options}
          label="Επιλέξτε υπάλληλο"
          value={selectedEmployeeId}
          onChange={handleEmployeeChange}
        />
        {selectedEmployeeId && (
          <MyCal
            localizer={localizer}
            events={filteredEvents}
            dayLayoutAlgorithm="no-overlap"
            startAccessor="start"
            endAccessor="end"
            style={{ minHeight: 700, backgroundColor: 'white' }}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            onRangeChange={handleRangeChange} // Month / Week/ Agenda [enter only here]
            onNavigate={handleNavigate}
            views={['month', 'week', 'agenda']}
            showMultiDayTimes
            step={60}
            messages={{
              next: 'Επόμενο',
              previous: 'Προηγούμενο',
              today: 'Σήμερα',
              month: 'Μήνας',
              week: 'Εβδομάδα',
              day: 'Ημέρα',
              agenda: 'Λίστα',
              date: 'Ημερομηνία',
              time: 'Ώρα',
              event: 'Συμβάν',
              noEventsInRange: 'Δεν υπάρχουν στοιχεία',
            }}
          />
        )}
        <DetailsModal open={open} selectedEvent={selectedEvent} setOpen={setOpen} />
        {employeeError && <GenericModalError errorMessage={employeeError} />}
        {modalVisible &&
        <GenericModal
        errmsg={msgData}
        failedmsg={msgDataError?.message}
          modaltype={'calender'}
          loaderVisible={loaderVisible}
          clearAction={clearModal}
        />}
      </div>
    </Layout>
  );
};

export default Calendar;
