import { Dispatch, SetStateAction, useState } from 'react';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PaletteMode } from '@mui/material';

import myErgani from '../../assets/img/my-ergani.png';

import StyledDrawer from './styledComponents/StyledDrawer';
import SideBarItems from './styledComponents/SideBarItems';
import PrimarySearchAppBar from '../PrimarySearchAppBar/PrimarySearchAppBar';

type GlobalHeaderMenuProps = {
  mode: PaletteMode;
  setMode: Dispatch<SetStateAction<PaletteMode>>;
};

const GlobalHeaderMenu = ({ mode, setMode }: GlobalHeaderMenuProps) => {
  const [open, setOpen] = useState(true);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <PrimarySearchAppBar mode={mode} open={open} toggleColorMode={toggleColorMode} toggleDrawer={toggleDrawer} />
      <StyledDrawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            px: [1],
          }}
        >
          {open && (
            <>
              <img
                src={myErgani}
                style={{
                  width: '150px',
                  height: 'auto',
                  cursor: 'pointer',
                  padding: '15px',
                }}
                alt="logo of egrani app"
              />
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
        <List component="nav">
          <SideBarItems />
        </List>
      </StyledDrawer>
    </>
  );
};

export default GlobalHeaderMenu;
