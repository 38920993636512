import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/el';

import { GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material';

import CustomTabPanel from '../../../components/TabWrapper/CustomTabPanel';
import DataGridContainer from '../../../components/DataGridContainer/DataGridContainer';
import { getOneMonthBack } from '../../../helpers/date.helper';
import { ICalendarDTO } from '../../../models/calendar';
import { useAppDispatch, useAppSelector } from '../../../store/app-hooks';
import GenericModalError from '../../../components/Modal/GenericModalError';
import { selectCardActions, selectCardActionsError } from '../../../store/cardAction/selectors';
import { getCardActions_Action } from '../../../store/cardAction/cardActionSlice';

type CardMovementsProps = {
  tab: number;
  employeeId: any;
};

const columns: GridColDef[] = [
  { field: 'type', headerName: 'Τύπος', flex: 1, renderCell: (param) => <>{param.row.type}</> },
  {
    field: 'time',
    headerName: 'Ημερομηνία Ενέργειας',
    flex: 1,
    renderCell: (param) => <>{param.row.time}</>,
  },
];

const CardMovements = ({ tab, employeeId }: CardMovementsProps) => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(getOneMonthBack());
  const [endDate, setEndDate] = useState<any>(dayjs());

  const cardActions = useAppSelector(selectCardActions);

  const cardActionsError = useAppSelector(selectCardActionsError);
  // console.log("cardActions", cardActions);
  const rowsFinal = cardActions
    ? cardActions.map((rowItem, index) => {
        const date = new Date(rowItem.time);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

        return {
          ...rowItem,
          id: index,
          time: formattedDateTime,
          type: rowItem.type === 0 ? 'Προσέλευση' : 'Αποχώρηση',
        };
      })
    : [];
    console.log("rowsFinal", rowsFinal);
  useEffect(() => {
    const payload: ICalendarDTO = {
      EmployeeID: employeeId,
      FromDate: startDate.format('DD/MM/YYYY'),
      ToDate: endDate.format('DD/MM/YYYY'),
    };

    dispatch(getCardActions_Action(payload));
  }, [employeeId, startDate, endDate]);

  return (
    <CustomTabPanel value={tab} index={1}>
      <Grid item flex={0.7} container columnSpacing={1} spacing={1} justifyContent="flex-end">
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
            <DatePicker
              onChange={(newValue) => setStartDate(newValue)}
              format="DD/MM/YYYY"
              value={startDate}
              label="Από"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
            <DatePicker onChange={(newValue) => setEndDate(newValue)} format="DD/MM/YYYY" value={endDate} label="Έως" />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box sx={{ height: 600, width: '100%', overflowY: 'auto' }}>
        <DataGridContainer rows={rowsFinal} columns={columns} />
      </Box>
      {cardActionsError && <GenericModalError errorMessage={cardActionsError} />}
    </CustomTabPanel>
  );
};

export default CardMovements;
